import React, { useState, useEffect } from 'react'
import $ from "jquery"
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import apiurl from '../../Global';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '../includes/Loading';
import base_config from '../../Config';
import Sidebar from '../includes/Sidebar';
import moment from 'moment/moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
const cookies = new Cookies();

export default function Sales() {
  const SalesPages = (props) => {
    
    const colors = { "a" : '#007FFF', "b" : '#0000FF', "c" : '#7B3F00', "d" : '#4B0082', "e" : '#05472A', "f" : '#E25822', "g" : '#FFD700', "h" : '#F0FFF0', "i" : '#4B0082', "j" : '#343434', "k" : '#C3B091', "l" : '#E6E6FA', "m" : '#FF00FF', "n" : '#000080', "o" : '#FFA500', "p" : '#FFE5B4', "q" : '#436B95', "r" : '#FF0000', "s" : '#C0C0C0', "t" : '#D2B48C', "u" : '#120A8F', "v" : '#EE82EE', "w" : '#FFFFFF', "x" : '#738678', "y" : '#FFFF00', "z" : '#0014A8', }

    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const params = useParams()
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, warningModal, assets_url} = CommonFunc()
    const [refresh, setRefresh] = useState("No")
    const [loading, setLoading] = useState(false)
    
    const [addtionalDataShow, setAdditionalDataShow] = useState(false)
    const [addtionalDataLoading, setAdditionalDataLoading] = useState(false)
    const [showGraph, setShowGraph] = useState("")
    const [addtionalData, setAdditionalData] = useState([])
    const selectedCourses = (props.salescourses !== null && props.salescourses['salescourses'] !== undefined) ? props.salescourses['salescourses'] : []
    const [tab, setTab] = useState("Any")
    const [orders, setOrders] = useState([])
    const [total, setTotal] = useState(0)
    const [totalOutof, setTotalOutof] = useState(0)
    const [statecitylist, setStatecitylist] = useState({state:[], city:[], pincodes:[], categories:[]})
    const [filter, setFilter] = useState({
        "prefixid" : "",
        "payment_id" : "",
        "userdata" : "",
        "information" : "",
        "mode" : [],
        "paid" : "",
        "from" : "",
        "to" : "",
        "gstclaimed" : "",
        "gst_no" : "",
        "service_type" : "",
        "reference_no" : "",
        "type" : "",
        "state" : "",
        "city" : "",
        "pincode" : "",
        "expired" : "",
        "expiremin" : "",
        "expiremax" : "",
        "amountmin" : "",
        "amountmax" : "",
        "promocode_applied" : "",
        "promocode" : "",
        "promocode_link_applied" : "",
        "linkpromocode" : "",
        "books" : "",
        "showescholarstudents" : "",
        "showduplicate" : "",
        "coins" : "",
        "categories" : "",
        "selectedCourses" : selectedCourses
    })
    const [cancel, setCancel] = useState(null)


    const [segment, setSegment] = useState(0)
    
    const getOrders = (currentPage = 1) => {
        setLoading(true)
        var temp = filter
        temp['categories'] = window.$("#categories").val()
        temp['mode'] = window.$("#channel").val()
        temp['courseLIst'] = window.$("#courseLIst").val()
        
        fetch(apiurl + "api/ordersNew?page="+currentPage, {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    type: tab,
                    filter: temp,
                    fromsales: 1,
                    selectedCourses: selectedCourses
                })
            })
            .then(response => response.json())
            .then(response => {
                setOrders(response.orders)
                setLastPage(response.lastpage)
                // setTimeout(function(){
                //     window.$('#studenttable').dataTable()
                // }, 500)
                var temp = statecitylist;
                temp['city'] = response.statecitylist.city
                temp['state'] = response.statecitylist.state
                temp['pincodes'] = response.statecitylist.pincodes
                temp['categories'] = response.statecitylist.categories
                setStatecitylist(temp)
                setSummary(response.summary)
                setRefresh(refresh === "Yes" ? "No" : "Yes")
                setLoading(false)
                setAdditionalDataShow(false)
                getAddiotional()
                
                setTotal(response.total)
                setTotalOutof(response.totalOutof)
                
                var max = 10000
                setMaxStudents((max < response.total) ? max : response.total)
                setMaxStudentsFix((max < response.total) ? max : response.total)

                setTimeout(() => {
                    window.$("#categories").unbind().select2();
                    window.$("#channel").unbind().select2();
                }, 500);
                // document.body.scrollTop = document.documentElement.scrollTop = 0;
            })
    }
    const changeFilter = (name, value) => {
        var temp = filter
        temp[name] = value
        setFilter(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")
    }

    const changeCancel = (id) => {
        var temp = {
            id : id,
            msg : ""
        }
        setCancel(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")
    }

    const changeReason = (msg) => {
        var temp = cancel
        temp['msg'] = msg
        setCancel(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")
    }

    const [summary, setSummary] = useState(null)

    const cancelCourse = (e) => {
        e.preventDefault()
        formLoading(e.target)
        
        fetch(apiurl + "api/cancelOrder", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    cancel: cancel
                })
            })
            .then(response => response.json())
            .then(response => {
                $(e.target).find(".loaderbody").fadeOut()
                setTimeout(function(){  
                    $(e.target).find(".loaderbody").remove()
                }, 1000);
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                    setCancel(null)
                    getOrders(1)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
    }

    const [minStudents, setMinStudents] = useState(1)
    const [maxStudents, setMaxStudents] = useState(10000)
    const [maxStudentsFix, setMaxStudentsFix] = useState(10000)
    
    const [lastPage, setLastPage] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)


    const [downloading, setDownloading] = useState(false)
    const [downloadingPopup, setDownloadingPopup] = useState(false)
    const downloadRequestFetch = () => {

        const min = parseInt(minStudents)
        const max = parseInt(maxStudents)
        const diff = max - (min-1);
        if(max < min){
            errorModal("Error", '"FROM" Cannot be greater than "TO"')
        }else if(diff > maxStudentsFix){
            errorModal("Error", "Total Users can't be more than 10000")
        }else{
            setDownloading(true)
            
            var temp = filter
            temp['mode'] = window.$("#channel").val()
            temp['courseLIst'] = window.$("#courseLIst").val()

            setDownloadingPopup(false)
            fetch(apiurl+"api/user/getOrdersForDownload", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        courseid: params.id,
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1,
                        filter: temp,
                        min: min,
                        max: max
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status === "OK"){
                        // console.log(response.students)
                        downloadRequest("", response.students)
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                })
                .catch(err => { });
        }

        
    }
    
    const downloadRequest = (type = "", orders = orders) => {
        var filename = ""
        var th = []
        var td = []
        if(type == ""){
            setDownloading(true)
            th = ["S.No", "Date", "Student ID", "Student Name", "Student Email", "Student Contact", "Shipping Contact", "Alternative Contact", 
            "Booking Date",	"AWB ID", "Tracking Link",
            "Course/Package Name", "Plan Name", "Address", "Address Line 2", "State", "City", "Pincode", "Landmark", "Course/Package Type", "Status", 
            // "Listing Price", "Selling Price", 
            "Amount Paid", "Coins Used", "Earned CB", "Promocode Discount", "Promocode Used", 
            "Expiry", "Order ID", "Payment ID", "Channel" ]
            var sn = 1
            const defaddress = {
                "name": "-",
                "email": "-",
                "contact": "-",
                "alternative_no": "-",
                "state": "-",
                "city": "-",
                "pincode": "-",
                "address2": "-",
                "landmark" : "-"
            }
            console.log(defaddress, "DEFAUKLT ADDRESS")
            var segmentTemp = orders
            for(var j = 0; j < segmentTemp.length; j++){
                var temp = orders[j]
                for(var i = 0;i<temp.length;i++){
                    var info = JSON.parse(temp[i]['information'])
                    var userdata = JSON.parse(temp[i]['userdata'])
                    var address = (temp[i]['address'] === null || temp[i]['address'] === "") ? defaddress : JSON.parse(temp[i]['address'])

                    var exp = (temp[i]['expiredate'] !== null) ? new Date(temp[i]['expiredate'] * 1000) : null
                    var consignment = (temp[i]['consignment'] !== null) ? JSON.parse(temp[i]['consignment']) : null
                    
                    // var processdata = (temp[i]['processdata'] !== null) ? JSON.parse(temp[i]['processdata']) : null
                    //  processdata = (processdata !== null && processdata[1] !== undefined) ? processdata[1]['orderProcessed'] : null
                    var bookingdate = temp[i]['bookingdate']
                     var trackid = (consignment !== null && consignment['type'] !== undefined) ? consignment['consignments'][0]['other']['track_id'] : temp[i]['reference']
                     var trackurl = (consignment !== null && consignment['type'] !== undefined) ? consignment['consignments'][0]['other']['track_url'] : ""

                     
                    //  var conignmentType = (consignment['consignments'][0]['other'] !== undefined) ? consignment['consignments'][0]['other'] : null
                    // var conignmentType = null
                    //  console.log(consignment)
                    //  var consignment = null
                    //  var processdata = ""
                    
                    
                    // var letter = Array.from(userdata.name)[0];
                    // var process = JSON.parse(temp[i]['processdata'])
                    // console.log(address, "asd")
                    moment.locale('en');
                    var ct = temp[i]['created_at'];

                    
                    var addr = (address['address'] !== undefined) ? address['address'].replace(/,/g, " ") : "";
                    var addr2 = (address['address2'] !== undefined && address['address2'] !== null) ? address['address2'].replace(/,/g, " ") : "";
                    var state = (address['state'] !== undefined) ? address['state'].replace(/,/g, " ") : "";
                    var city = (address['city'] !== undefined) ? address['city'].replace(/,/g, " ") : "";
                    var pincode = (address['pincode'] !== undefined) ? address['pincode'].replace(/,/g, " ") : "";
                    var landmark = (address['landmark'] !== undefined && address['landmark'] !== null) ? address['landmark'].replace(/,/g, " ") : "";
                    
                    td.push(
                        [
                            sn,
                            moment(ct).format("D-M-Y hh:mm:s A"), temp[i]['uid'], userdata['name'], userdata['email'], userdata['contact'], address['contact'], address['alternative_no'], 
                            
                            bookingdate, trackid, trackurl,
                            // (consignment !== null) ? consignment[]

                            info.course.name, info['plan']['plan_name'], addr, addr2, state, city, pincode, landmark, 
                            temp[i]['type'], (temp[i]['payment_id'].startsWith("INIT_") ? 'Initiated' : 'Success'), 
                            // info['plan']['listing_price'], info['plan']['selling_price'], 
                            temp[i]['paid'], info['price']['deduct'], info['price']['cb'],
                            (info['price']['promocode'] !== undefined && info['price']['promocode'] !== null && info['price']['promocode']['promodiscount'] !== undefined) ? info['price']['promocode']['promodiscount'] : 0,
                            (info['price']['promocode'] !== undefined && info['price']['promocode'] !== null && info['price']['promocode']['promodiscount'] !== undefined) ? "#" + info['price']['promocode']['code'] : '-',
                            temp[i].exp_date, 
                            temp[i]['prefixid'],
                            temp[i]['payment_id'], temp[i]['mode'],  
                            
                        ]
                    )
                    sn++
                    
                }
            }
            filename = "Request to download Sales List of ("+td.length+") Entries."
        }else{
            th = ["Name", "Count"]
            var temp = addtionalData[type]
            for(var i = 0;i<temp.length;i++){
                td.push(
                    [
                        temp[i]['lable'], temp[i]['count'], 
                    ]
                )
            }
            filename = "Request to download Top "+type+" List of ("+td.length+") Entries."
        }

        
        fetch(apiurl+"api/downloadLists", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    th: th,
                    td: td,
                    for: filename
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setDownloading(false)
            })
            .catch(err => {
                // console.log(err);
                setDownloading(false)
            });

    }


    const clearFilter = (e) => {
        window.location.reload()
        // var temp = filter
        // temp = {
        //     "prefixid" : "",
        //     "payment_id" : "",
        //     "userdata" : "",
        //     "information" : "",
        //     "mode" : [],
        //     "paid" : "",
        //     "from" : filter.from,
        //     "to" : filter.to,
        //     "gstclaimed" : "",
        //     "gst_no" : "",
        //     "service_type" : "",
        //     "reference_no" : "",
        //     "type" : "",
        //     "state" : "",
        //     "city" : "",
        //     "pincode" : "",
        //     "expired" : "",
        //     "expiremin" : "",
        //     "expiremax" : "",
        //     "amountmin" : "",
        //     "amountmax" : "",
        //     "promocode_applied" : "",
        //     "promocode" : "",
        //     "promocode_link_applied" : "",
        //     "linkpromocode" : "",
        //     "books" : "",
        //     "showescholarstudents" : "",
        //     "showduplicate" : "",
        // }

        // window.$("#categories").val('').change();
        // setFilter(temp)
        // // filterFeedbacks(e)
        // setRefresh(refresh === 'No' ? "Yes" : "No")
        // setTimeout(function(){
        //     window.$("#channel").unbind().select2();
        //     convertCoursesList()
        // }, 500)
    }

    const getAddiotional = () => {
        setAdditionalDataLoading(true)
        setAdditionalDataShow(true)
        
        fetch(apiurl + "api/getTopCitiesV2", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    filter: filter,
                })
            })
            .then(response => response.json())
            .then(response => {
                setAdditionalDataLoading(false)
                setAdditionalData(response.data)
            })
    }

    const [rmLoading, setRMloading] = useState(false)
    
    const markAll = () => {
        if($("#markAll").prop('checked')){
            $('.mark_checkbox:checkbox').prop('checked', true);
        }else{
            $('.mark_checkbox:checkbox').prop('checked', false);
        }
    }

    const removeCourses = () => {
        var check = []
        window.$(".mark_checkbox:checkbox:checked").each(function () {
            check.push($(this).val());
        });
        if(check < 1){
            warningModal("Please select atleast 1 course/package first", "")
        }else{
            removeDialog(check)
        }
    }

    const removeDialog = (ids) => {
        confirmAlert({
            title: 'Are You Sure?',
            message: "You want to remove these orders from portal?",
            buttons: [
                {
                label: 'Yes',
                onClick: () => RemoveBulk(ids)
                },
                {
                label: 'No',
                onClick: () => console.log("Canceled")
                }
            ]
        });
    }
    const RemoveBulk = (ids) => {
        if(!rmLoading){
            setRMloading(true)
            fetch(apiurl + "api/removePurchasesBulk", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1,
                        ids: ids,
                    })
                })
                .then(response => response.json())
                .then(response => {
                    successModal("Success", ids.length + " Course/Packages Removed Successfully.")
                    setRMloading(false)
                    getOrders(1)
                })
            }
    }

    const [statsLoading, setStatsLoading] = useState(false)
    const calculateStats = () => {
        if(!statsLoading){
            setStatsLoading(true)
            var temp = filter
            temp['categories'] = window.$("#categories").val()
            temp['mode'] = window.$("#channel").val()
            fetch(apiurl + "api/ordersSalesData?page="+currentPage, {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1,
                        type: tab,
                        filter: temp,
                        fromsales: 1,
                        selectedCourses: selectedCourses
                    })
                })
                .then(response => response.json())
                .then(response => {
                    setSummary(response.summary)
                    setRefresh(refresh === "Yes" ? "No" : "Yes")
                    setStatsLoading(false)
                })
        }
    }

    const [coursesList, setCoursesList] = useState([])
    const  getCourses = () => {
        fetch(apiurl + "api/coursesList", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(response.status === "OK"){
                    setCoursesList(response.courses)
                    convertCoursesList()
                }
            })
    }
    const convertCoursesList = () => {
        window.$("#courseLIst").unbind().select2({
            templateResult: addCoursePic,
            templateSelection: addCoursePic
        })
        function addCoursePic(opt) {
            if (!opt.id) {
                return opt.text;
            }
            var optimage = $(opt.element).data('image');
            if (!optimage) {
                return opt.text;
            } else {
                var $opt = $(
                  '<span class="userName"><img src="' + assets_url + optimage + '" class="userPic" /> ' + $(opt.element).text() + '</span>'
                );
                return $opt;
            }
        };
    }

    const getGraph = (graph) => {
        setShowGraph(graph)
        setTimeout(function(){
            window.amchart("mapChart", addtionalData.graphs)
        }, 500);
    }
    
    var a = 0
    useEffect(() => {
      if(a === 0){
        getCourses()
        getOrders(1)
        a++
      }  
    }, [])


    return(
        <>
        
            
        {
            downloadingPopup &&
            <div className='dialog' id='downloadPopup'>
                <div className='col-md-4'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Export Users: <small style={{color:"red"}} >current filters' values will be used</small>
                            </h3>
                            <span className='pull-right cursor-pointer' onClick={() => setDownloadingPopup(false)} >
                                <i className='fa fa-times'></i>
                            </span>
                        </div>
                        <div className='box-body'>
                            <label>From</label>
                            <input type='number' className='form-control' placeholder='e.g 1' value={minStudents} onChange={(e) => setMinStudents(e.target.value)} />
                        </div>
                        <div className='box-body'>
                            <label>To</label>
                            <input type='number' className='form-control' placeholder='e.g 100' value={maxStudents} onChange={(e) => setMaxStudents(e.target.value)} />
                        </div>
                        <p style={{color:"red", paddingLeft:10}}>
                            <strong>
                                Total Users can't be more than {maxStudentsFix}
                            </strong>
                        </p>
                        <div className='box-footer'>
                            <button className='btn btn-primary btn-flat btn-block' onClick={() => downloadRequestFetch()} >Download Students</button>
                        </div>
                    </div>
                </div>
            </div>
    }
        <div className='col-md-12'>
        {
            pagePermissions(props.pagepermissions, "Sales_Page", "show_filters") &&
            <>
        <div className='box no-border'>
                <div className='box-header'>
                    <h3 className='box-title'>
                        Filter Order(s)
                    </h3>
                        <button className='btn btn-warning btn-flat pull-right' onClick={(e) => clearFilter(e)}>
                            Clear Filter
                        </button>
                </div>
                {/* <form action='' method='POST'> */}
                    <div className='box-body'>
                        <div className='col-md-3 no-left-padding'>
                            <label>
                                Order ID
                            </label>
                            <input type={"text"} className="form-control" placeholder='e.g COURSE1669028852' value={filter.prefixid} onChange={(e) => changeFilter("prefixid", e.target.value)} />
                        </div>
                        <div className='col-md-3'>
                            <label>
                                Payment ID
                            </label>
                            <input type={"text"} className="form-control" placeholder='e.g MOJO2b21L05A80543294' value={filter.payment_id} onChange={(e) => changeFilter("payment_id", e.target.value)}  />
                        </div>
                        <div className='col-md-3 '>
                            <label>
                                Student (Name, Email, Contact)
                            </label>
                            <input type={"text"} className="form-control" placeholder='e.g info@example.com' value={filter.userdata} onChange={(e) => changeFilter("userdata", e.target.value)}  /> 
                        </div>
                        <div className='col-md-3 no-right-padding'>
                            <label>
                                Course/Package
                            </label>
                            <input type={"text"} className="form-control" placeholder='e.g CA Foundation' value={filter.information} onChange={(e) => changeFilter("information", e.target.value)}  />
                        </div>
                    </div>
                    <div className='box-body' style={{display:"flex", alignItems:"flex-end"}}>
                        
                        <div className='col-md-3 no-left-padding'>
                            <label>
                                Channel
                            </label>
                            <select className='form-control' id='channel' data-placeholder='Select Channel' multiple defaultValue={filter.mode} onChange={(e) => changeFilter("mode", e.target.value)}  >
                                <option value={"Web"}>Web</option>
                                <option value={"Android"}>Android</option>
                                <option value={"Ios"}>Ios</option>
                                <option value={"Admin"}>By Admin</option>
                            </select>
                        </div>
                        <div className='col-md-3'>
                            <label>
                                Paid/Free
                            </label>
                            <select className='form-control' defaultValue={filter.paid} onChange={(e) => changeFilter("paid", e.target.value)}  >
                                <option value={""}>Any</option>
                                <option value={"Initiated"}>Initiated</option>
                                <option value={"Paid"}>Paid</option>
                                <option value={"Free"}>Free</option>
                            </select>
                        </div>
                        <div className='col-md-3 '>
                            <label>
                                From
                            </label>
                            <input type={"date"} className="form-control" value={filter.from} onChange={(e) => changeFilter("from", e.target.value)}  /> 
                        </div>
                        <div className='col-md-3 no-right-padding'>
                            <label>
                                To
                            </label>
                            <input type={"date"} className="form-control" value={filter.to} onChange={(e) => changeFilter("to", e.target.value)}  /> 
                        </div>
                    </div>
                    <div className='box-body' style={{display:"flex", alignItems:"flex-end"}}>
                        
                        <div className='col-md-3 no-left-padding'>
                            <label>
                                GST Claimed
                            </label>
                            <select className='form-control' defaultValue={filter.gstclaimed} onChange={(e) => changeFilter("gstclaimed", e.target.value)}  >
                                <option value={""}>Any</option>
                                <option value={"Yes"}>Yes</option>
                                <option value={"No"}>No</option>
                            </select>
                        </div>
                        <div className='col-md-3 '>
                            <label>
                                GST No
                            </label>
                            <input type={"text"} className="form-control" value={filter.gst_no} onChange={(e) => changeFilter("gst_no", e.target.value)} placeholder={"e.g 12345678912345"}  /> 
                        </div>
                        <div className='col-md-3'>
                            <label>
                                Service Type
                            </label>
                            <select className='form-control' defaultValue={filter.service_type} onChange={(e) => changeFilter("service_type", e.target.value)}  >
                                <option value={""}>Any</option>
                                <option value={"GROUND EXPRESS"} >GROUND EXPRESS</option>
                                <option value={"PRIORITY"} >PRIORITY</option>
                            </select>
                        </div>
                        <div className='col-md-3 no-right-padding'>
                            <label>
                                AWB No
                            </label>
                            <input type={"text"} className="form-control" value={filter.reference_no} onChange={(e) => changeFilter("reference_no", e.target.value)} placeholder={"e.g D77346338"} /> 
                        </div>
                    </div>
                    <div className='box-body' style={{display:"flex", alignItems:"flex-end"}}>
                        <div className='col-md-3 no-left-padding'>
                            <label>
                                State
                            </label>
                            <select className='form-control' defaultValue={filter.state} onChange={(e) => changeFilter("state", e.target.value)}  >
                                <option value={""}>Any</option>
                                {
                                    statecitylist.state.length > 0 &&
                                    statecitylist.state.map((item, index) => {
                                        return(
                                            <option key={"state"+index} value={item.state}>{item.state.toUpperCase()}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='col-md-3'>
                            <label>
                                City
                            </label>
                            <select className='form-control' defaultValue={filter.city} onChange={(e) => changeFilter("city", e.target.value)}  >
                                <option value={""}>Any</option>
                                {
                                    statecitylist.city.length > 0 &&
                                    statecitylist.city.map((item, index) => {
                                        return(
                                            <option key={"city"+index} value={item.city}>{item.city.toUpperCase()}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='col-md-3 '>
                            <label>
                                Pincode
                            </label>
                            <select className='form-control' defaultValue={filter.pincode} onChange={(e) => changeFilter("pincode", e.target.value)}  >
                                <option value={""}>Any</option>
                                {
                                    statecitylist.pincodes.length > 0 &&
                                    statecitylist.pincodes.map((item, index) => {
                                        return(
                                            <option key={"pincode"+index} value={item.pincode}>{item.pincode.toUpperCase()}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='col-md-3 no-right-padding'>
                            <label>
                                Expired?
                            </label>
                            <select className='form-control' defaultValue={filter.expired} onChange={(e) => changeFilter("expired", e.target.value)}  >
                                <option value={""}>Any</option>
                                <option value={"Expired"}>Expired</option>
                                <option value={"NotExpired"}>Not Expired</option>
                            </select>
                        </div>
                    </div>
                    <div className='box-body' style={{display:"flex", alignItems:"flex-end"}}>
                        <div className='col-md-3 no-left-padding'>
                            <label>
                                Expiring Date (From)
                            </label>
                            <input type={"datetime-local"} className="form-control" value={filter.expiremin} onChange={(e) => changeFilter("expiremin", e.target.value)}  /> 
                        </div>
                        <div className='col-md-3'>
                            <label>
                                Expiring Date (To)
                            </label>
                            <input type={"datetime-local"} className="form-control" value={filter.expiremax} onChange={(e) => changeFilter("expiremax", e.target.value)}  /> 
                        </div>
                        <div className='col-md-3'>
                            <label>
                                Paid Amount (Min)
                            </label>
                            <input type={"number"} className="form-control" value={filter.amountmin} onChange={(e) => changeFilter("amountmin", e.target.value)} placeholder={"e.g 1"}  /> 
                        </div>
                        <div className='col-md-3 no-right-padding'>
                            <label>
                                Paid Amount (Max)
                            </label>
                            <input type={"number"} className="form-control" value={filter.amountmax} onChange={(e) => changeFilter("amountmax", e.target.value)} placeholder={"e.g 2000"}  /> 
                        </div>
                    </div>
                    
                    <div className='box-body' style={{display:"flex", alignItems:"flex-end"}}>
                        
                        <div className='col-md-3 no-left-padding'>
                            <label>
                                Promocode Applied?
                            </label>
                            <select className='form-control' value={filter.promocode_applied} onChange={(e) => changeFilter("promocode_applied", e.target.value)}  >
                                <option value={""}>Any</option>
                                <option value={"Yes"}>Yes</option>
                                <option value={"No"}>No</option>
                            </select>
                        </div>
                        <div className='col-md-3 '>
                            <label>
                                Promocode
                            </label>
                            <input type={"text"} className="form-control" value={filter.promocode} onChange={(e) => changeFilter("promocode", e.target.value)} placeholder={"e.g WELCOME100"}  /> 
                        </div>
                        <div className='col-md-3'>
                            <label>
                                Promocode Link Applied
                            </label>
                            <select className='form-control' value={filter.promocode_link_applied} onChange={(e) => changeFilter("promocode_link_applied", e.target.value)}  >
                                <option value={""}>Any</option>
                                <option value={"Yes"} >Yes</option>
                                <option value={"No"} >No</option>
                            </select>
                        </div>
                        <div className='col-md-3 no-right-padding'>
                            <label>
                                Link Promocode
                            </label>
                            <input type={"text"} className="form-control" value={filter.linkpromocode} onChange={(e) => changeFilter("linkpromocode", e.target.value)} placeholder={"e.g D77346338"} /> 
                        </div>
                    </div>
                    <div className='box-body' style={{display:"flex", alignItems:"flex-end"}}>
                        <div className='col-md-3 no-left-padding'>
                            <label>
                                Type
                            </label>
                            {/* <input type={"text"} className="form-control" value={filter.gst_no} onChange={(e) => changeFilter("gst_no", e.target.value)} placeholder={"e.g 12345678912345"}  />  */}
                            
                            <select className='form-control' defaultValue={filter.type} onChange={(e) => changeFilter("type", e.target.value)}  >
                                <option value={""}>Any</option>
                                <option value={"Package"} >Package</option>
                                <option value={"Course"} >Course</option>
                            </select>
                        </div>
                        <div className='col-md-3 '>
                            <label>
                                Books Included
                            </label>
                            {/* <input type={"text"} className="form-control" value={filter.gst_no} onChange={(e) => changeFilter("gst_no", e.target.value)} placeholder={"e.g 12345678912345"}  />  */}
                            
                            <select className='form-control' defaultValue={filter.books} onChange={(e) => changeFilter("books", e.target.value)}  >
                                <option value={""}>Any</option>
                                <option value={"Yes"} >Yes</option>
                                <option value={"No"} >No</option>
                            </select>
                        </div>
                        <div className='col-md-3 '>
                            <label>
                                Show Escholar Students
                            </label>
                            {/* <input type={"text"} className="form-control" value={filter.gst_no} onChange={(e) => changeFilter("gst_no", e.target.value)} placeholder={"e.g 12345678912345"}  />  */}
                            
                            <select className='form-control' defaultValue={filter.showescholarstudents} onChange={(e) => changeFilter("showescholarstudents", e.target.value)}  >
                                <option value={""}>Any</option>
                                <option value={"Yes"} >Yes</option>
                                <option value={"No"} >No</option>
                            </select>
                        </div>
                        <div className='col-md-3 no-right-padding'>
                            <label>
                                Show Duplicates
                            </label>
                            {/* <input type={"text"} className="form-control" value={filter.gst_no} onChange={(e) => changeFilter("gst_no", e.target.value)} placeholder={"e.g 12345678912345"}  />  */}
                            
                            <select className='form-control' defaultValue={filter.showduplicate} onChange={(e) => changeFilter("showduplicate", e.target.value)}  >
                                <option value={""} >Yes</option>
                                <option value={"No"} >No</option>
                            </select>
                        </div>
                    </div>
                    <div className='box-body'>
                        <div className='col-md-3 no-left-padding'>
                            <label>
                                Coins Used?
                            </label>
                            {/* <input type={"text"} className="form-control" value={filter.gst_no} onChange={(e) => changeFilter("gst_no", e.target.value)} placeholder={"e.g 12345678912345"}  />  */}
                            
                            <select className='form-control' defaultValue={filter.coins} onChange={(e) => changeFilter("coins", e.target.value)}  >
                                <option value={""} >Any</option>
                                <option value={"Yes"} >Yes</option>
                                <option value={"No"} >No</option>
                            </select>
                        </div>
                        <div className="col-md-9 no-right-padding">
                            <label> Select Categories </label>
                            <select className='form-control' multiple id='categories' data-placeholder='Select Category'>
                                {
                                    statecitylist.categories.length > 0 &&
                                    statecitylist.categories.map((item, index) => {
                                        return(
                                            <option key={"cat"+index} value={item.value}>{item.lable.toUpperCase()}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    
                    <div className='box-body'>
                        <label>Select Courses/Packages</label>
                        <select name='coursesList[]' className='form-control' multiple id='courseLIst' data-placeholder="Select Courses/Packages" >
                            {
                                coursesList.length > 0 &&
                                coursesList.map((item, index) => {
                                    return(
                                        <option key={"cour"+index} data-image={item.thumbnail} value={item.otherid} >{item.name}</option>
                                    )
                                })
                            }
                            
                        </select>
                    </div>

                    <div className='box-footer'>
                        <input type={"submit"} value={"Search"} className="btn btn-primary btn-flat pull-right btn-block" onClick={() => getOrders(1)} />
                    </div>
                {/* </form> */}
            </div>
        
        </>
        }
        </div>
        {
            summary !== null &&
<>
        {
            pagePermissions(props.pagepermissions, "Sales_Page", "paid_courses_enrolled_box") &&
        <div className="col-lg-3 col-xs-6">
          <div className="small-box bg-my">
            <div className="inner">
              <h3>{ (statsLoading) ? "Calculating.." : summary.totalPaidCourses}</h3>
              <p>Paid Courses Enrolled</p>
            </div>
            <div className="icon">
              <i className="fa fa-book"></i>
            </div>
            <a href="#" className="small-box-footer">
              {/* More info <i className="fa fa-arrow-circle-right"></i> */}
            </a>
          </div>
        </div>
        }
        {
            pagePermissions(props.pagepermissions, "Sales_Page", "paid_package_enrolled_box") &&
        <div className="col-lg-3 col-xs-6">  
          <div className="small-box bg-my">
            <div className="inner">
              <h3>{ (statsLoading) ? "Calculating.." : summary.totalPaidPackages}</h3>

              <p>Paid Packages Enrolled</p>
            </div>
            <div className="icon">
              <i className="fa fa-book"></i>
            </div>
            <a href="#" className="small-box-footer">
              {/* More info <i className="fa fa-arrow-circle-right"></i> */}
            </a>
          </div>
          
        </div>
        }
        {
            pagePermissions(props.pagepermissions, "Sales_Page", "free_courses_enrolled_box") &&
        <div className="col-lg-3 col-xs-6">
          <div className="small-box bg-my">
            <div className="inner">
              <h3>{ (statsLoading) ? "Calculating.." : summary.totalFreeCourses}</h3>

              <p>Free Courses Enrolled</p>
            </div>
            <div className="icon">
              <i className="fa fa-book"></i>
            </div>
            <a href="#" className="small-box-footer">
              {/* More info <i className="fa fa-arrow-circle-right"></i> */}
            </a>
          </div>
        </div>
        }
        {
            pagePermissions(props.pagepermissions, "Sales_Page", "free_package_enrolled_box") &&
        <div className="col-lg-3 col-xs-6">
          <div className="small-box bg-my">
            <div className="inner">
              <h3>{ (statsLoading) ? "Calculating.." : summary.totalFreePackages}</h3>

              <p>Free Packages Enrolled</p>
            </div>
            <div className="icon">
              <i className="fa fa-book"></i>
            </div>
            <a href="#" className="small-box-footer">
              {/* More info <i className="fa fa-arrow-circle-right"></i> */}
            </a>
          </div>
        </div>
        }


        {
            pagePermissions(props.pagepermissions, "Sales_Page", "total_amount_box") &&
        <div className="col-lg-3 col-xs-6">
          <div className="small-box bg-my">
            <div className="inner">
              <h3>{ (statsLoading) ? "Calculating.." : summary.totalAmount}</h3>
              <p>Total Amount</p>
            </div>
            <div className="icon">
              <i className="fa fa-rupee"></i>
            </div>
            <a href="#" className="small-box-footer">
              {/* More info <i className="fa fa-arrow-circle-right"></i> */}
            </a>
          </div>
        </div>
        }
        {
            pagePermissions(props.pagepermissions, "Sales_Page", "total_package_amount_box") &&
        <div className="col-lg-3 col-xs-6">
          <div className="small-box bg-my">
            <div className="inner">
              <h3>{ (statsLoading) ? "Calculating.." : summary.packageAmount}</h3>

              <p>Total Packages Amount</p>
            </div>
            <div className="icon">
              <i className="fa fa-rupee"></i>
            </div>
            <a href="#" className="small-box-footer">
              {/* More info <i className="fa fa-arrow-circle-right"></i> */}
            </a>
          </div>
        </div>
        }
        {
            pagePermissions(props.pagepermissions, "Sales_Page", "total_course_amount_box") &&
        <div className="col-lg-3 col-xs-6">  
          <div className="small-box bg-my">
            <div className="inner">
              <h3>{ (statsLoading) ? "Calculating.." : summary.courseAmount}</h3>

              <p>Total Courses Amount</p>
            </div>
            <div className="icon">
              <i className="fa fa-rupee"></i>
            </div>
            <a href="#" className="small-box-footer">
              {/* More info <i className="fa fa-arrow-circle-right"></i> */}
            </a>
          </div>
          
        </div>
        }
        {
            pagePermissions(props.pagepermissions, "Sales_Page", "total_inits_box") &&
        <div className="col-lg-3 col-xs-6">
          <div className="small-box bg-my">
            <div className="inner">
              <h3>{ (statsLoading) ? "Calculating.." : summary.totalInits}</h3>

              <p>Total Initiated</p>
            </div>
            <div className="icon">
              <i className="fa fa-book"></i>
            </div>
            <a href="#" className="small-box-footer">
              {/* More info <i className="fa fa-arrow-circle-right"></i> */}
            </a>
          </div>
        </div>
        }
        </>
  }
  {
    <div className="col-md-12">
        <div className='box no-border' style={{overflow:'hidden'}} >
            <div className='box-body'>
                <button className='btn btn-warning btn-flat btn-block' onClick={() => calculateStats()}>Calculate Sales Statistics</button>
            </div>
        </div>
    </div>
  }
        {
            loading ?
        <div className='col-md-12'>
            <div className='box no-border'>
                <div className='box-body' style={{paddingTop:"100px", paddingBottom: "100px"}}>
                    <Loading />
                </div>
            </div>
        </div>
    :
    <>
    {
        addtionalDataShow &&
        <div className='col-md-12'>
            <div className='box no-border'>
                {/* <div className='box-header'>
                    <h3 className='box-title'>
                        Show Additional Information
                    </h3>
                    <button className='btn btn-primary btn-flat pull-right' onClick={() => setAdditionalDataShow(false)}>
                        <i className='fa fa-times'></i>
                    </button>
                </div> */}
                {
                    addtionalDataLoading &&
                    <div className='box-body' style={{position:"relative"}} >
                        <br /><br /><br />
                        <Loading />
                        <br /><br /><br />
                    </div>
                }
                {
                    !addtionalDataLoading &&
                    
                    <>
                    {
                        pagePermissions(props.pagepermissions, "Sales_Page", "top_cities") &&
                        (showGraph === "" || showGraph === "Cities") &&
                        <div className='col-md-4 no-left-padding'>
                            <br />
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Top Cities
                                    </h3>
                                        {
                                            pagePermissions(props.pagepermissions, "Sales_Page", "show_download_button") &&
                                            <>
                                                <button className='btn btn-primary btn-flat pull-right' style={{marginRight:"10px"}} onClick={() => downloadRequest("cities")} >
                                                    Download
                                                </button>
                                            </>
                                        }
                                </div>
                                <div className='box-body' style={{maxHeight:"400px", overflow:"auto"}}>
                                    <table className="table table-bordered table-hover datatable">
                                        <thead>
                                            <tr>
                                                <th style={{width:"40px"}} >S.No</th>
                                                <th>Name</th>
                                                <th>Orders</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                addtionalData.cities.length > 0 &&
                                                addtionalData.cities.map((item, index) => {
                                                    return(
                                                        <tr key={"cilty" + index} >
                                                            <td style={{width:"40px"}} >{index+1}</td>
                                                            <td>{item.lable}</td>
                                                            <td>{item.count}</td>
                                                        </tr>
                                                        
                                                        )
                                                    })
                                                }
                                        </tbody>
                                    </table>
                                </div>
                                <div className='box-footer'>
                                    <button className='btn btn-primary btn-flat btn-block' style={{cursor:"no-drop"}}>
                                        ------
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        pagePermissions(props.pagepermissions, "Sales_Page", "top_states") &&
                        (showGraph === "" || showGraph === "States") &&
                        <div className='col-md-4'>
                            <br />
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Top States
                                    </h3>
                                        {
                                            pagePermissions(props.pagepermissions, "Sales_Page", "show_download_button") &&
                                            <>
                                                <button className='btn btn-primary btn-flat pull-right' style={{marginRight:"10px"}} onClick={() => downloadRequest("states")} >
                                                    Download
                                                </button>
                                            </>
                                        }
                                </div>
                                <div className='box-body' style={{maxHeight:"400px", overflow:"auto"}}>
                                    <table className="table table-bordered table-hover datatable">
                                        <thead>
                                            <tr>
                                                <th style={{width:"40px"}} >S.No</th>
                                                <th>Name</th>
                                                <th>Orders</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                addtionalData.states.length > 0 &&
                                                addtionalData.states.map((item, index) => {
                                                    return(
                                                        <tr key={"cilty" + index} >
                                                            <td style={{width:"40px"}} >{index+1}</td>
                                                            <td>{item.lable}</td>
                                                            <td>{item.count}</td>
                                                        </tr>
                                                        
                                                        )
                                                    })
                                                }
                                        </tbody>
                                    </table>
                                </div>
                                <div className='box-footer'>
                                    <button className='btn btn-primary btn-flat btn-block' onClick={() => getGraph("States")}>
                                        Get Graph
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        pagePermissions(props.pagepermissions, "Sales_Page", "top_pincodes") &&
                        (showGraph === "" || showGraph === "Pincodes") &&
                        <div className='col-md-4 no-right-padding'>
                            <br />
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Top Pincodes
                                    </h3>
                                        {
                                            pagePermissions(props.pagepermissions, "Sales_Page", "show_download_button") &&
                                            <>
                                                <button className='btn btn-primary btn-flat pull-right' style={{marginRight:"10px"}} onClick={() => downloadRequest("pincodes")} >
                                                    Download
                                                </button>
                                            </>
                                        }
                                </div>
                                <div className='box-body' style={{maxHeight:"400px", overflow:"auto"}}>
                                    <table className="table table-bordered table-hover datatable">
                                        <thead>
                                            <tr>
                                                <th style={{width:"40px"}} >S.No</th>
                                                <th>Name</th>
                                                <th>Orders</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                addtionalData.pincodes.length > 0 &&
                                                addtionalData.pincodes.map((item, index) => {
                                                    return(
                                                        <tr key={"cilty" + index} >
                                                            <td style={{width:"40px"}} >{index+1}</td>
                                                            <td>{item.lable}</td>
                                                            <td>{item.count}</td>
                                                        </tr>
                                                        
                                                        )
                                                    })
                                                }
                                        </tbody>
                                    </table>
                                </div>
                                <div className='box-footer'>
                                    <button className='btn btn-primary btn-flat btn-block' style={{cursor:"no-drop"}}>
                                        ------
                                    </button>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        pagePermissions(props.pagepermissions, "Sales_Page", "top_states") &&
                        (showGraph === "States") &&
                        <>
                            <div className='col-md-8'>
                                <div className='box no-border'>
                                    <div className='box-header'>
                                        <h3 className='box-title'>
                                            State Graph
                                        </h3>
                                        <span className='badge bg-red pull-right' onClick={() => setShowGraph("")}>
                                            <i className='fa fa-times'></i>
                                        </span>
                                    </div>
                                    <div className='box-body' style={{position:"relative"}}>
                                        <div className='hideChartLogo'></div>
                                        <div id="mapChart" style={{ width: "100%", height: "500px" }}></div>
                                    </div>
                                </div>

                            </div>
                        </>
                    }
                    </>
                }
            </div>
        </div>
    }
    
    {
        // !addtionalDataShow &&
        //     <div className='col-md-12'>
        //         <div className='box no-border'>
        //             <div className='box-header'>
        //                 <h3 className='box-title'>
        //                     Show Additional Information
        //                 </h3>
        //                 <button className='btn btn-primary btn-flat pull-right' onClick={() => getAddiotional()}>
        //                     Show Addional Data
        //                 </button>
        //             </div>
        //         </div>
        //     </div>
        }
    
        <div className={ (cancel === null) ? "col-md-12" : "col-md-8"}>
            <div className="nav-tabs-custom">
            <div className="tab-content">
                <div className="tab-pane active" id="tab_1" style={{backgroundColor:"#FFFFFF", overflow:"auto"}} >
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Manage Sales <strong>({total + "/" + totalOutof})</strong>
                        </h3>
                        
                        {
                            pagePermissions(props.pagepermissions, "Sales_Page", "remove_bulk") &&
                                <button className='btn btn-danger btn-flat pull-right' onClick={() => removeCourses()}>
                                    {
                                        !rmLoading ? 'Remove' : 'Loading...'
                                    }
                                    
                                </button>
                        }
                        
                        {
                            pagePermissions(props.pagepermissions, "Sales_Page", "show_download_button") &&
                            <>
                                {
                                    !downloading ?
                                    <button className='btn btn-primary btn-flat pull-right' style={{marginRight:"10px"}} onClick={() => setDownloadingPopup(true)} >
                                        Download
                                    </button>
                                    :
                                    <button className='btn btn-warning btn-flat pull-right' >
                                        Preparing...
                                    </button>
                                }
                            </>
                        }

                    </div>
                    <table id="studenttable" className="table table-bordered table-hover datatable">
                        <thead>
                            <tr>
                                {
                                    pagePermissions(props.pagepermissions, "Sales_Page", "remove_bulk") &&
                                    <th >
                                        <input type={"checkbox"} id={"markAll"} onChange={() => markAll()}/>
                                    </th>
                                }
                                <th style={{width:"40px"}} >S.No</th>
                                <th>Student</th>
                                {
                                    cancel === null &&
                                    <th>Contact</th>
                                }
                                <th>Course/Package</th>
                                <th>Order ID</th>
                                <th>Payment ID</th>
                                {
                                    (tab !== 'unprocessed') &&
                                    <th>AWb No</th>
                                }
                                {
                                    cancel === null &&
                                    <>
                                        <th>Type</th>
                                        <th>Channel</th>
                                        <th>Amount</th>
                                        <th className='text-center' >Date</th>
                                    </>
                                }
                                {
                                    (tab === 'canceled') &&
                                    <th>Reason</th>
                                }
                                <th>Expiring On</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            {
                                typeof orders[segment] !== "undefined" &&
                                orders[segment].length > 0 &&
                                orders[segment].map((item, index) => {
                                    var info = JSON.parse(item.information)
                                    var userdata = JSON.parse(item.userdata)
                                    
                                    if(!pagePermissions(props.pagepermissions, "Sales_Page", "hide_students_info")){
                                        userdata['name'] = (userdata['name'].substring(0,3)) + '*'.repeat((userdata['name'].length > 3) ? userdata['name'].length - 3 : 1) 
                                        userdata['contact'] = (userdata['contact'].substring(0,4)) + "******"
                                        var expEmail = userdata['email'].split("@")
                                        userdata['email'] = (expEmail[0].substring(0,3)) + '*'.repeat((expEmail[0].length > 3) ? expEmail[0].length - 3 : 1) + "@" + ((expEmail[1] !== undefined) ? expEmail[1] : "")
                                    }
                                    var staff = (item.staff === null) ? null : JSON.parse(item.staff)
                                    var letter = Array.from(userdata.name)[0];
                                    var process = JSON.parse(item.processdata)
                                    var exp = (item.expiredate !== null) ? new Date(item.expiredate * 1000) : null
                                    moment.locale('en');
                                    var ct = item.created_at;
                                    var userid = item.uid.replace("TEMP", "");
                                    
                                    return(
                                        <tr key={"orders"+index} className={(cancel !== null && cancel.id === item.prefixid) ? 'background-red' : ''} style={{pointerEvents: (pagePermissions(props.pagepermissions, "Sales_Page", "hide_students_info") ? 'any' : 'none')}} >
                                            
                                            {
                                                pagePermissions(props.pagepermissions, "Sales_Page", "remove_bulk") &&
                                                <td>
                                                    <input type={"checkbox"} name="mark[]" className='mark_checkbox' value={item.purchase_id}/>
                                                </td>
                                            }
                                            <td  >{(index+1) * (segment+1)}</td>
                                            <td onClick={() => (pagePermissions(props.pagepermissions, "Sales_Page", "hide_students_info")) ? window.open('/admin/student-profile/'+userid) : ''}>
                                                <div className='studentName'>
                                                    <div className='profilePic' style={{color:colors[letter.toLowerCase()], backgroundColor: colors[letter.toLowerCase()]+"29"}}>
                                                        {letter.toUpperCase()}
                                                    </div>
                                                    <span style={{whiteSpace:"nowrap"}} >
                                                        <strong>{userdata.name}</strong><br />
                                                    </span>
                                                </div>
                                            </td>
                                            {
                                                cancel === null &&
                                            <td style={{color:"#3c8dbc"}} >
                                                <strong style={{whiteSpace:"nowrap"}} className="cursor-pointer" title={'Send Mail On '+userdata.email} onClick={() => window.location.href='mailto:'+userdata.email} > 
                                                    <i className='fa fa-envelope'></i> {userdata.email}
                                                </strong><br />
                                                <strong style={{whiteSpace:"nowrap"}} className="cursor-pointer" title={'Make Call On '+userdata.contact} onClick={() => window.location.href='tel:'+userdata.contact} ><i className='fa fa-phone'></i> {userdata.contact}</strong><br />
                                            </td>
                                            }
                                            <td>
                                                <Link to={"/admin/course/" + item.course_id } style={{color: (item.needhelp !== null) ? "red" : "" }}>
                                                    <strong>
                                                        {info.course.name}&nbsp;
                                                        
                                                        {
                                                            info.course.books !== null &&
                                                            <i className='fa fa-book'></i>
                                                        }
                                                        {
                                                            item.needhelp !== null &&
                                                            <>
                                                            <span style={{color:"red"}} >
                                                            &nbsp;
                                                            <i className='fa fa-info-circle'></i>
                                                            &nbsp;
                                                            </span>
                                                            </>
                                                        }
                                                        {
                                                            info.price.deduct !== undefined && info.price.deduct > 0 &&
                                                            <span style={{color:"#EFCD5A"}} >
                                                            &nbsp;
                                                            <i className='fa fa-database' ></i>
                                                            </span>
                                                        }
                                                    </strong>
                                                </Link>
                                            </td>
                                            <td>
                                                {
                                                    pagePermissions(props.pagepermissions, "Sales_Page", "hide_students_info") ?
                                                        <Link to={"/admin/order-info/"+item.purchase_id+"/"+userid} title={"View Order Information"} target={"_blank"} >
                                                            <strong>
                                                                {item.prefixid}
                                                            </strong>
                                                        </Link>
                                                        :
                                                    <strong>
                                                        {item.prefixid}
                                                    </strong>
                                                }
                                            </td>
                                            <td>{item.payment_id}</td>
                                            {
                                                (tab !== 'unprocessed') &&
                                                <td>{item.reference}</td>
                                            }
                                            {
                                                cancel === null &&
                                                <>
                                                    <td>
                                                        {item.type}
                                                    </td>
                                                    <td>
                                                        {
                                                            item.mode === "Admin" && staff !== null ?
                                                            <Link to={'/admin/staff?id='+staff.id} target='_blank' >{item.mode}</Link>
                                                            :
                                                            item.mode
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            info.plan.plan_name !== undefined &&
                                                            <>
                                                                <small style={{whiteSpace:"nowrap"}} >Plan Name: {info.plan.plan_name}</small><br />
                                                            </>
                                                        }
                                                        
                                                        {
                                                            info.plan.listing_price > 0 &&
                                                            <>
                                                                <small style={{whiteSpace:"nowrap"}} >Listing Price: {info.plan.listing_price}</small><br />
                                                            </>
                                                        }
                                                        {
                                                            info.plan.selling_price > 0 &&
                                                            <>
                                                                <small style={{whiteSpace:"nowrap"}} >Selling Price: {info.plan.selling_price}</small><br />
                                                            </>
                                                        }
                                                        {
                                                            item.paid > 0 &&
                                                            <>
                                                                <small style={{whiteSpace:"nowrap"}} >Amount Paid: {(item.paid === "0" || item.paid === 0) ? 'Free' : item.paid}</small><br />
                                                            </>
                                                        }
                                                        {
                                                            info.price.deduct > 0 &&
                                                            <>
                                                                <small style={{whiteSpace:"nowrap"}} >Coins Used: {info.price.deduct}</small><br />
                                                            </>
                                                        }
                                                        {
                                                            info.price.cb > 0 &&
                                                            <>
                                                                <small style={{whiteSpace:"nowrap"}} >Earned CB: {info.price.cb}</small><br />
                                                            </>
                                                        }
                                                        {
                                                             info.price.promocode !== undefined && info.price.promocode !== null && info.price.promocode.promodiscount > 0 &&
                                                            <>
                                                                <small style={{whiteSpace:"nowrap"}} >Promocode Discount: {info.price.promocode.promodiscount} (#{info.price.promocode.code})</small><br />
                                                            </>
                                                        }
                                                        {
                                                            info.plan.custom_discount !== undefined &&
                                                            <span><strong>CUSTOM PLAN</strong></span>
                                                        }
                                                    </td>
                                                    <td style={{whiteSpace:"nowrap", textAlign:"center"}}>
                                                        {moment(ct).format("D-M-Y hh:mm:s A")}
                                                    </td>
                                                </>
                                            }
                                            {
                                                (tab === 'canceled') &&
                                                <td>
                                                    <span style={{whiteSpace:"nowrap"}}>
                                                        Canceled on: 
                                                        <strong>
                                                            {process.cancelDate}
                                                        </strong>
                                                    </span>
                                                    <br />
                                                    <span style={{whiteSpace:"nowrap"}}>
                                                        Reason: 
                                                        <strong>
                                                            {process.cancelReason}
                                                        </strong>
                                                    </span>
                                                </td>
                                            }
                                            <td>
                                                {
                                                    (item.expiredate !== null) ? 
                                                    <>
                                                    {exp.toLocaleString("en-IN")}
                                                    </>
                                                    // (new Date(item.expiredate * 1000))
                                                    :
                                                    "-"
                                                }
                                            </td>
                                        </tr>
                                                
                                        )
                                    })
                            }
                        </tbody>
                    </table>

                </div>
                <div className='box-footer'>
                    <div className='segMentPagination text-right' style={{width:"100%", margin:"20px 0"}} >
                        {                
                            <div style={{width:"200px", float:"right"}}>
                                <label>Last Page: {lastPage}</label>
                                <div className="input-group input-group-sm">
                                        <input type={"number"} className="form-control" placeholder='e.g 1' value={currentPage} onChange={(e) => setCurrentPage(e.target.value) } />
                                        <span className="input-group-btn">
                                            <button type="button" className="btn btn-primary btn-flat" onClick={() => getOrders(currentPage)}>Go!</button>
                                        </span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            
            </div>
            
        </div>
        {
            cancel !== null &&
            <div className={ "col-md-4"}>
                <form action='' method='POST' onSubmit={(e) => cancelCourse(e)} >
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Cancelation Reason
                            </h3>
                        </div>
                        <div className='box-body'>
                            <textarea className='form-control' value={cancel.msg} rows="5" placeholder='e.g Fake Order' onChange={(e) => changeReason(e.target.value)} ></textarea>
                        </div>
                        <div className='box-footer'>
                            <input type={"submit"} className="btn btn-danger btn-flat btn-block" value={"Cancel Order"} /> 
                        </div>
                    </div>
                </form>
            </div>
        }
    </>
    }
    
        </>
    )
  }
  return (
    <>
      <Sidebar page={SalesPages} permission="Sales_Page" />
    </>
  )
}
