import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import Cookies from 'universal-cookie';
import Adminprops from '../../VelidateWebsite/Adminprops'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import apiurl from '../../Global';
import CommonFunc from '../includes/Common';
import { confirmAlert } from 'react-confirm-alert'; // Import
import moment from 'moment';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const cookies = new Cookies();
 
const useQuery = () => {
    return new URLSearchParams(useLocation().search)
}
const colors = { "a" : '#007FFF', "b" : '#0000FF', "c" : '#7B3F00', "d" : '#4B0082', "e" : '#05472A', "f" : '#E25822', "g" : '#FFD700', "h" : '#F0FFF0', "i" : '#4B0082', "j" : '#343434', "k" : '#C3B091', "l" : '#E6E6FA', "m" : '#FF00FF', "n" : '#000080', "o" : '#FFA500', "p" : '#FFE5B4', "q" : '#436B95', "r" : '#FF0000', "s" : '#C0C0C0', "t" : '#D2B48C', "u" : '#120A8F', "v" : '#EE82EE', "w" : '#FFFFFF', "x" : '#738678', "y" : '#FFFF00', "z" : '#0014A8', }
export default function Staff() {
  const Staff = (props) => {
    const query = useQuery();
    const [id, setId] = useState("")
    const [profilephoto, setProfilephoto] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [contact, setContact] = useState("")
    const [roleTag, setRoleTag] = useState("")
    const [groupTag, setGroupTag] = useState("")

    const [roleList, setRoleList] = useState([])
    const [groupsList, setGroupsList] = useState([])
    const [staffList, setStaffList] = useState([])
    const [hideNewRole, setHideNewRole] = useState(false)

    const [loading, setLoading] = useState(true)
    const adminProps = Adminprops()
    const admin = cookies.get('admin')
    const navigate = useNavigate()
    let getToken = cookies.get('token')
    const {AdminBasics, successModal, warningModal, errorModal, formLoading, pagePermissions, assets_url} = CommonFunc()
    
    const [refres, setRefresh] = useState("No")
    const [addStaff, setAddStaff] = useState(false)
    const [profileData, setProfileData] = useState(null)



    var a = 0
    useEffect(() => {
        if(a == 0){
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            if(query.get("id") === null ){
                get_staff();
            }else{
                get_staffDetails()
            }
            a++
        }
    }, [])

    const get_staffDetails = () => {
        fetch(apiurl+"api/staff_list", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    staffdata: query.get("id"),
                    getLogins: true,
                    staff: AdminBasics(),
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    response.staff.staff = response.staff.staff === null ? null : JSON.parse(response.staff.staff)
                    response.staff.lastmodby = response.staff.lastmodby === null ? null : JSON.parse(response.staff.lastmodby)
                    setProfileData(response.staff)

                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });    
    }

    const get_staff = () => {
        
        fetch(apiurl+"api/staff_list", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    // console.log(response)
                    setRoleList(response.roles)
                    setGroupsList(response.groups)
                    setStaffList(response.staff)
                    setTimeout(function(){
                        if(window.$("#staff_table_wrapper").length < 1){
                            window.$("#staff_table").dataTable({
                                "bPaginate": false
                            })
                        }
                    }, 2000)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const addMember = (e) => {
        
        formLoading(e.target)
        e.preventDefault();
        setLoading(true)
        
        const formData = new FormData();
        formData.append('staff', JSON.stringify(AdminBasics()));
        formData.append('accecible', 1);
        formData.append('page_url', window.location.href);
        formData.append('id', id)
        formData.append('roletag', roleTag)
        formData.append('name', name)
        formData.append('email', email);
        formData.append('contact', contact);
        formData.append('password', password);
        formData.append('groupTag', groupTag);
        formData.append('profilephoto', profilephoto);
        
        fetch( (id === "") ? apiurl+"api/add_new_member" : apiurl+"api/update_member" ,  {
            "method": "POST",
            "headers": {
                // "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": formData
            })
            .then(response => response.json())
            .then(response => {
                
                $(e.target).find(".loaderbody").fadeOut()
                setTimeout(function(){  
                    $(e.target).find(".loaderbody").remove()
                }, 1000);
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    
                    setEmail("")
                    setName("")
                    setContact("")
                    setRoleTag("")
                    setGroupTag("")
                    setId("")
                    setPassword("")
                    successModal("Success", response.message)
                    get_staff();
                    // $('#add_staff').trigger("reset");

                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const changeRole = (x) => {
        setRoleTag(x)
        if(x !== "New"){
            setHideNewRole(false)
        }else{
            setHideNewRole(true)
        }
    }
    const changeStatus = (id, target, index) => {
        const temp = staffList;
        var newstatus = 0
        if(target.value == 1){
            temp[index].status = 0
        }else{
            temp[index].status = 1
            newstatus = 1
        }
        setStaffList(temp)
        setRefresh((refres == "No") ? "Yes" : "No")
        fetch(apiurl+"api/update_staff_status", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    value: newstatus,
                    id: id,
                    staff: AdminBasics(),
                    accecible: 1
                })
            })
            .then(response => response.json())
            .then(response => {

                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
            })
            .catch(err => {
                // console.log(err);
            });
    }


    const removeStaff = (id) => {
        fetch(apiurl+"api/remove_staff", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    id: id,
                    staff: AdminBasics(),
                    accecible: 1
                })
            })
            .then(response => response.json())
            .then(response => {

                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                    get_staff()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const removeDialog = (id) => {
        confirmAlert({
            title: 'Are You Sure?',
            message: "You won't be able to revert this back.",
            buttons: [
              {
                label: 'Yes',
                onClick: () => removeStaff(id)
              },
              {
                label: 'No',
                onClick: () => console.log("Canceled")
              }
            ]
        });
    }


    

    const logoutFromAll = (item) => {
        fetch(apiurl+"api/logoutFromAll", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    id: item.id,
                    staff: AdminBasics(),
                    accecible: 1
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const editStaff = (item) => {
        console.log(item)
        setId(item.id)
        setName(item.name)
        setEmail(item.email)
        setContact(item.contact)
        setGroupTag(item.permissiongroup)
        setRoleTag(item.role)
        setGroupTag(item.permissiongroupid)
        setAddStaff(true)
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }


    const downloadRequest = () => {
        var temp = staffList
        const th = [
            "S.No", "Name", "Email", "Contact", "Role", "Created At", 
            "Created By Name", "Created By Email", "Created By Contact", 
            "Last Updated By Name", "Last Updated By Email", "Last Updated By Contact",
            "Last Updated Date & Time"
        ]
        var td = [];
        var sn = 1
        for(var i = 0;i<temp.length;i++){
            var staff = (temp[i].staff !== null) ? JSON.parse(temp[i].staff) : null
            var lastmod = (temp[i].lastmodby !== null) ? JSON.parse(temp[i].lastmodby) : null
            moment.locale('en');
            var ct = temp[i]['created_at'];
            var ut = temp[i]['updated_at'];
            td.push(
                [
                    sn,
                    temp[i]['name'], temp[i]['email'], temp[i]['contact'], temp[i]['role'], moment(ct).format("D-M-Y HH:mm:ss"), 
                    (staff !== null) ? staff.name + " ("+staff.role+")" : "-", (staff !== null) ? staff.email : "-", (staff !== null) ? staff.contact : "-", 
                    (lastmod !== null) ? lastmod.name + " ("+lastmod.role+")" : "-", (lastmod !== null) ? lastmod.email : "-", (lastmod !== null) ? lastmod.contact : "-",
                    moment(ut).format("D-M-Y HH:mm:ss")
                ]
            )
            sn++
        }
        
        
        fetch(apiurl+"api/downloadLists", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    th: th,
                    td: td,
                    for: "Request to download Staff List ("+td.length+")"
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
            })
            .catch(err => {
                // console.log(err);
            });

    }

    return(
        <>

        {
            
            pagePermissions(props.pagepermissions, "Staff", "add_staff") && addStaff &&
            

      <div className='col-md-4'>
        <div className='box no-border'>
          <div className='box-header'>
            <h3 className='box-title'>
            { (id === "") ? "Add New Member" : "Updated Member"}
            </h3>
                <button className='btn btn-flat btn-primary pull-right' style={{marginLeft:"10px"}} onClick={() => setAddStaff(false)} >
                    <i className='fa fa-times'></i>
                </button>
          </div>
          <form action='' method='POST' onSubmit={addMember} id="add_staff">
            <div className='box-body'>
                <label>Member Profile Photo</label>
                <input type={"file"} name="profilephoto" className='form-control' onChange={e => setProfilephoto(e.target.files[0])} accept='.png, .jpg, .jpeg, .gif, .svg'/>
            </div>
            <div className='box-body'>
                <label>Member Name<span className='text-red'>*</span></label>
                <input type={"text"} name="name" placeholder='e.g John Doe' value={name} className='form-control' required onChange={e => setName(e.target.value)}/>
            </div>
            {
                id === "" &&
                <div className='box-body'>
                    <label>Member Email<span className='text-red'>*</span></label>
                    <input type={"text"} name="email" placeholder='e.g info@example.com' value={email} className='form-control' required onChange={e => setEmail(e.target.value)} />
                </div>
            }
            <div className='box-body'>
                <label>Member Contact<span className='text-red'>*</span></label>
                <input type={"text"} name="contact" placeholder='e.g +9122222233' value={contact} className='form-control' required onChange={e => setContact(e.target.value)} />
            </div>
            <div className='box-body'>
                <label>Member Password</label>
                <input type={"text"} name="contact" placeholder='e.g Shivam@123' value={password} className='form-control' onChange={e => setPassword(e.target.value)} />
            </div>
            
            <div className='box-body'>
                <label>Permission Group</label>
                <select name='roletag' className='form-control' value={groupTag} onChange={e => setGroupTag(e.target.value)} >
                    <option value={""}>No Permissions</option>
                    {
                        groupsList.length > 0 &&
                        groupsList.map((item, index)=>(
                            <option value={item.groupid} key={"group"+index}>{item.name}</option>
                        ))
                    }
                </select>
            </div>
            <div className='box-body'>
                <label>Member Role Tag<span className='text-red'>*</span></label>
                <select name='roletag' className='form-control' value={roleTag} onChange={e => changeRole(e.target.value)} >
                    <option value={""}>Select Role</option>
                    {
                        roleList.length > 0 &&
                        roleList.map((item, index)=>(
                            <option value={item.role} key={"roles"+index}>{item.role}</option>
                        ))
                    }
                    <option value={"New"}>Assign New Role</option>
                </select>
            </div>
            
            {
                hideNewRole &&
                <div className='box-body'>
                    <label>Member Role Tag<span className='text-red'>*</span></label>
                    <input type={"text"} name="roletag" placeholder='e.g Sub Admin' className='form-control' onChange={e => setRoleTag(e.target.value)} />
                </div>
            }
            <div className='box-footer'>
                <input type={"submit"} className="btn btn-primary btn-flat pull-right" value={ (id === "") ? "Add Member" : "Updated Member"} required />
            </div>
            
          </form>
        </div>
      </div>
  }
  {
    profileData !== null && pagePermissions(props.pagepermissions, "Staff", "view_staff_info") &&
    <>
        <div className='col-md-4' >
            <div className="box box-widget widget-user">
                <div className="widget-user-header " style={{backgroundColor: colors[profileData.name[0].toLowerCase()]}}>
                {/* <h3 className="widget-user-username">{profileData.name}</h3>
                <h5 className="widget-user-desc">{profileData.email}</h5> */}
                </div>
                <div className="widget-user-image">
                <img className="img-circle" src={(profileData.profile_photo === null) ? '/assets/assets/images/user.png' : apiurl + profileData.profile_photo } alt="User Avatar" />
                </div>
                <div className="box-footer no-padding">
                    <ul className="nav nav-stacked">
                        <li><a href="#">Name <span className="pull-right">{profileData.name}</span></a></li>
                        <li><a href="#">Email <span className="pull-right">{profileData.email}</span></a></li>
                        <li><a href="#">Contact No <span className="pull-right">{profileData.contact}</span></a></li>
                        <li><a href="#">Role <span className="pull-right">{profileData.role}</span></a></li>
                        <li><a href="#">AddedB By <span className="pull-right">{profileData.staff === null ? "-" : profileData.staff.name}</span></a></li>
                        <li><a href="#">Last Activity on <span className="pull-right">{moment(profileData.lastactive).format("D-M-Y hh:mm:s A")}</span></a></li>
                    </ul>
                </div>
                
            </div>
        </div>
        {
            profileData.staff !== null && pagePermissions(props.pagepermissions, "Staff", "staff_modify_info") &&
            <div className='col-md-4' >
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Added By
                        </h3>
                    </div>
                    <div className="box-footer no-padding">
                        <ul className="nav nav-stacked">
                            <li><Link to={"/admin/staff?id="+profileData.staff.id} target='_blank' >Name <span className="pull-right">{profileData.staff.name}</span></Link></li>
                            <li><a href="#">Email <span className="pull-right">{profileData.staff.email}</span></a></li>
                            <li><a href="#">Contact No <span className="pull-right">{profileData.staff.contact}</span></a></li>
                            <li><a href="#">Role <span className="pull-right">{profileData.staff.role}</span></a></li>
                            
                        </ul>
                    </div>
                </div>
            </div>
        }
        {
            profileData.lastmodby !== null && pagePermissions(props.pagepermissions, "Staff", "staff_modify_info") &&
            <div className='col-md-4' >
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            last Modified By
                        </h3>
                    </div>
                    <div className="box-footer no-padding">
                        <ul className="nav nav-stacked">
                            <li><Link to={"/admin/staff?id="+profileData.lastmodby.id} target='_blank' >Name <span className="pull-right">{profileData.lastmodby.name}</span></Link></li>
                            <li><a href="#">Email <span className="pull-right">{profileData.lastmodby.email}</span></a></li>
                            <li><a href="#">Contact No <span className="pull-right">{profileData.lastmodby.contact}</span></a></li>
                            <li><a href="#">Role <span className="pull-right">{profileData.lastmodby.role}</span></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        }
        {
            profileData.logins !== null && profileData.logins.length > 0 && pagePermissions(props.pagepermissions, "Staff", "staff_longgedin_summary") &&
            <div className='col-md-4'>
            <div className="box no-border">
                <div className="box-header with-border">
                        <h3 className="box-title">Logged In Summary</h3>
                    </div>
                    <div className="box-body" style={{maxHeight:"400px", overflow:"auto"}} >
                    {
                        profileData.logins.map((item, index) => {
                            moment.locale('en');
                            var dt = item.created_at;
                            const ip = JSON.parse(item.info) 
                            var icon = "desktop"
                            if(item.device === "Android"){ icon = "android" }
                            if(item.device === "Ios" || item.device === "IOS" || item.device === "mac" || item.device === "Mac"){ icon = "apple" }
                            
                            return(
                                <div key={"login"+index} >
                                    <strong>
                                        <i className="fa fa-calendar margin-r-5"></i> {moment(dt).format("D-M-Y HH:mm:ss")}
                                        <span className='pull-right'><i className={"fa fa-"+icon}></i></span>
                                    </strong>
                                    <p className="text-muted">
                                        IP Address: {ip.ip}
                                    </p>
                                    <p className="text-muted">
                                        <strong>City: </strong>{ip.cityName+", "+ip.countryName} ({ip.regionCode+" - "+ip.countryCode})
                                    </p>
                                    {
                                        ip.additional_information !== undefined &&
                                        <>
                                            {
                                                ip.additional_information.browserName !== "" && ip.additional_information.browserName !== null &&
                                                <p className="text-muted">
                                                    <strong>Browser: </strong>{ip.additional_information.browserName}
                                                </p>
                                            }
                                            {
                                                ip.additional_information.deviceFamily !== "" && ip.additional_information.deviceFamily !== null &&
                                                <p className="text-muted">
                                                    <strong>Device Brand: </strong>{ip.additional_information.deviceFamily}
                                                </p>
                                            }
                                            {
                                                ip.additional_information.deviceModel !== "" && ip.additional_information.deviceModel !== null &&
                                                <p className="text-muted">
                                                    <strong>Device Model: </strong>{ip.additional_information.deviceModel}
                                                </p>
                                            }
                                        </>
                                    }
                                    <hr />
                                </div>
                                
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        }
    </>
  }
  {
    pagePermissions(props.pagepermissions, "Staff", "view_staff") &&
    query.get("id") === null &&
    profileData === null &&
      <div className='col-md-12'>
        <div className='box no-border'>
            <div className='box-header'>
                <h3 className='box-title'>
                    Staff Members List ({staffList.length})
                </h3>
                {
                    !addStaff &&
                    <button className='btn btn-flat btn-primary pull-right' style={{marginLeft:"10px"}} onClick={() => setAddStaff(true)} >
                        <i className='fa fa-plus'></i>
                    </button>
                }
                <button className='btn btn-primary btn-flat pull-right' onClick={() => downloadRequest()} >
                    Download
                </button>
            </div>
            <div className='box-body' style={{overflow:"auto"}} >
                <table id="staff_table" className="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>
                                S.No
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                Email
                            </th>
                            <th>
                                Contact
                            </th>
                            <th>
                                Role
                            </th>
                            <th>
                                Permission Group
                            </th>
                            <th>
                                Created By
                            </th>
                            <th>
                                Last Modified By
                            </th>
                            <th>
                                Date
                            </th>
                            {
                            pagePermissions(props.pagepermissions, "Staff", "edit_staff") &&
                            <th>
                                Status
                            </th>
                            }
                            <th>
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            staffList.length > 0 &&
                            staffList.map((item, index) => {
                                moment.locale('en');
                                var ct = item.created_at; 
                                var staff = (item.staff !== null) ? JSON.parse(item.staff) : null
                                var lastmod = (item.lastmodby !== null) ? JSON.parse(item.lastmodby) : null
                                return(
                                    item.id !== admin.id &&
                                <tr key={"stafflits" + index}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <Link to={"/admin/staff?id="+item.id} >
                                            <div className="studentName forAdmin">
                                                <div className="profilePic">
                                                    {
                                                        item.profile_photo === null ?
                                                        <img src='/assets/assets/images/user.png'  />
                                                        :
                                                        <img src={assets_url + item.profile_photo}  />
                                                    }
                                                </div>
                                                <span>
                                                    <strong>{item.name}</strong>
                                                </span>
                                            </div>
                                        </Link>
                                    </td>
                                    <td>
                                        <a href={"mailto:" + item.email} >
                                            {item.email}
                                        </a>
                                    </td>
                                    <td>
                                        <a href={"tel:" + item.contact} >
                                            {item.contact}
                                        </a>
                                    </td>
                                    <td>
                                        {item.role}
                                    </td>
                                    <td>
                                        {
                                            item.permissiongroup !== undefined ?
                                            item.permissiongroup
                                            :
                                            "-"
                                        }
                                    </td>
                                    <td>
                                        {
                                            staff !== null &&
                                        <>
                                            <a href={'/admin/staff?id='+staff.id}>{ staff.name } (<small>{ staff.role }</small>)</a><br />
                                                <strong>
                                                        <a href={ "mailto:" + staff.email } title={"Mail On " + staff.email } >
                                                        { staff.email }
                                                        </a>
                                                </strong><br />
                                                <strong>
                                                        <a href={ "tel:" + staff.contact } title={"Call On " + staff.contact } >
                                                        { staff.contact }
                                                        </a>
                                                </strong><br />
                                            </>
                                        }
                                    </td>
                                    <td>
                                        {
                                            lastmod !== null &&
                                        <>
                                            <a href={'/admin/staff?id='+lastmod.id}> { lastmod.name } (<small>{ lastmod.role }</small>)</a><br />
                                                <strong>
                                                        <a href={ "mailto:" + lastmod.email } title={"Mail On " + lastmod.email } >
                                                        { lastmod.email }
                                                        </a>
                                                </strong><br />
                                                <strong>
                                                        <a href={ "tel:" + lastmod.contact } title={"Call On " + lastmod.contact } >
                                                        { lastmod.contact }
                                                        </a>
                                                </strong><br />
                                            </>
                                        }
                                    </td>
                                    <td>
                                        {moment(ct).format("D-M-Y HH:mm:ss")}
                                    </td>
                                    {
                                        pagePermissions(props.pagepermissions, "Staff", "edit_staff") &&
                                    <td>

                                        <label className="switch">
                                            <input type="checkbox" value={(item.status == 1) ? 1 : 0} defaultChecked={(item.status) ? true : false} onChange={e => changeStatus(item.id, e.target, index)}/>
                                            <span className="slider round"></span>
                                        </label>
                                    
                                    </td>
                                    }
                                    <td style={{whiteSpace:"nowrap"}} >
                                        {
                                            (pagePermissions(props.pagepermissions, "Staff", "edit_staff") || (admin.id === item.id)) &&
                                            <button className='btn btn-primary btn-flat' style={{marginRight:"5px"}} title={"Edit Member"} onClick={() => {
                                                editStaff(item)
                                            }}  >
                                                <i className='fa fa-edit'></i>
                                            </button>
                                        }
                                        
                                        {
                                            (pagePermissions(props.pagepermissions, "Staff", "edit_staff") || (admin.id === item.id)) &&
                                            <button className='btn btn-danger btn-flat' style={{marginRight:"5px"}} title={"Logout From All Devices"} onClick={() => logoutFromAll(item)}  >
                                                <i className='fa fa-sign-out'></i>
                                            </button>
                                        }
                                        
                                        {
                                            pagePermissions(props.pagepermissions, "Staff", "facility_staff") &&
                                            <Link to={"/admin/edit-facilities/"+item.id} title={"Staff Facilities"} >
                                                <button className='btn btn-primary btn-flat' style={{marginRight:"5px"}}>
                                                    Authorities
                                                </button>
                                            </Link>
                                        }

                                        {
                                            pagePermissions(props.pagepermissions, "Staff", "course_facility_staff") &&
                                            <Link to={"/admin/course-facilities/"+item.id} title={"Course Facilities"} >
                                                <button className='btn btn-primary btn-flat' style={{marginRight:"5px"}}>
                                                    Course Authorities
                                                </button>
                                            </Link>
                                        }
                                        
                                        {
                                            pagePermissions(props.pagepermissions, "Staff", "remove_staff") &&
                                            <button className='btn btn-danger btn-flat' onClick={e => removeDialog(item.id)}>
                                                <i className='fa fa-trash'></i>
                                            </button>
                                        }
                                    </td>
                                </tr>
                                        
                            )})
                        }
                    </tbody>
                </table>
            </div>
        </div>
      </div>
    }
      </>
    )
  }
  return (
    <>
      <Sidebar page={Staff} permission="Staff"/>
    </>
  )
}
